import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Layout } from '../components/layout';
import { DividerBlock } from '../components/dividerBlock';
import ImageFadeIn from "react-image-fade-in"

const Publishing = ({ data }) => {
  const { publishing } = data;

  return (
    <Layout
      bgcolor={publishing.backgroundColor}
      wrapperClass="publishing"
      accentColor={publishing.accentColor}
    >
      <HelmetDatoCms seo={publishing.seoMetaTags} />
      <div className="main-block">
        <div className="main-title col">
          <h1 className="gothic--large large-title">{publishing.title}</h1>
        </div>
        <div className="main-image col">
					<Img fluid={publishing.featuredImage.fluid} className="featured-title-image" />
          <p
            className="caption"
          // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: publishing.featuredImage.title,
            }}
          />
        </div>
        <div className="main-intro row">
          <div
            className="intro-text col sans--regular"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: publishing.introduction }}
          />
          <div className="intro-divider col">
            <DividerBlock accentColor={publishing.accentColor} />
          </div>
        </div>
        <div
          className="content main-text col"
            // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: publishing.description,
          }}
        />
      </div>
      {publishing.programs.length > 0 && (
        <section aria-labelledby="publishing-p" className="publishing-programs container row">
          <div className="col-sm-2 col-xs-6 col">
            <h2 id="publishing-p" className="sidebar-text">{publishing.sideHeading}</h2>
          </div>
          <div className="col-sm-12 col-xs-6 col">
            {publishing.programs.map(
              ({
                text, id, link, linkText, heading, programs,
              }) => (
                <div className="itr image-text-row spacing-0" key={id}>
                  <div className="image-text-row-image-wrapper">
                    <Link to={`/${link}`}>
											<ImageFadeIn
												src={programs.url}
												alt={programs.alt}
											/>  
                    </Link>
                  </div>
                  <div className="image-text-row-text-wrapper">
                    <h3 className="sans--regular">{heading}</h3>
                    <div
                      className="content excerpt"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: text,
                      }}
                    />
                    <Link
                      className="section-link"
                      to={`/${link}`}
                    >
                      {linkText}
                    </Link>
                  </div>
                </div>
              ),
            )}
          </div>
        </section>
      )}
      <div className="col-sm-5 col-xs-4 offset-xs-2 offset-sm-3 col">
        <DividerBlock accentColor={publishing.accentColor} />
      </div>
    </Layout>
  );
};

Publishing.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default Publishing;

export const query = graphql`
  query PublishingQuery {
    publishing: datoCmsPublishing {
      accentColor {
        hex
      }
      backgroundColor
      description
      featuredImage {
				fluid(maxHeight: 600) {
          ...GatsbyDatoCmsSizes_noBase64
        }				
				alt
      }
      introduction
      title
      sideHeading
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      programs {
        text
        id
        link
        linkText
        heading
        programs {
          alt
					url(imgixParams: { w: "600", ar: "5:3", fit: "crop", crop: "focalpoint" })
        }
      }
    }
  }
`;
